<script setup lang="ts"></script>

<template>
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58171 0 0 3.58172 0 8C0 12.4183 3.58171 16 8 16ZM7.11111 4.44444C7.11111 3.95353 7.50911 3.55556 8 3.55556C8.49089 3.55556 8.88889 3.95353 8.88889 4.44444V7.11111H11.5556C12.0464 7.11111 12.4444 7.50908 12.4444 8C12.4444 8.49092 12.0464 8.88889 11.5556 8.88889H8.88889V11.5556C8.88889 12.0465 8.49089 12.4444 8 12.4444C7.50911 12.4444 7.11111 12.0465 7.11111 11.5556V8.88889H4.44444C3.95356 8.88889 3.55556 8.49092 3.55556 8C3.55556 7.50908 3.95356 7.11111 4.44444 7.11111H7.11111V4.44444Z"
            fill="url(#paint0_linear_521_11333)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_521_11333"
                x1="8"
                y1="0"
                x2="8"
                y2="16"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#A2BADC" />
                <stop offset="1" stop-color="#68799C" />
            </linearGradient>
        </defs>
    </svg>
</template>
